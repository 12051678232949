import {NEXT_PUBLIC_HCAPTCHA_SITEKEY} from 'config/index';
import {useEffect, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';

import HCaptcha from '@hcaptcha/react-hcaptcha';
import {FormHelperText} from '@mui/material';
import {useRouter} from "next/router";

export const Captcha = ({
  sitekey = NEXT_PUBLIC_HCAPTCHA_SITEKEY,
  size = 'normal',
}: {
  sitekey?: string;
  size?: 'normal' | 'compact' | 'invisible';
}) => {
  const [token, setToken] = useState(null);
  const captchaRef = useRef<any>(null);
  const {locale} = useRouter();

  const {
    formState: {errors},
    setValue,
    clearErrors,
    register,
  } = useFormContext();

  // const onLoad = () => {
  //   // this reaches out to the hCaptcha JS API and runs the
  //   // execute function on it. you can use other functions as
  //   // documented here:
  //   // https://docs.hcaptcha.com/configuration#jsapi
  //   captchaRef.current.execute();
  // };
  const onLoad = () => {
    // this reaches out to the hCaptcha JS API and runs the
    // execute function on it. you can use other functions as
    // documented here:
    // https://docs.hcaptcha.com/configuration#jsapi
    captchaRef.current.execute();
  };

  useEffect(() => {
    if (token) {
      setValue('captchaToken', token);
      clearErrors('captchaToken');
    }
  }, [token]);

  //Removed due to hydration error
  //   if (!(typeof window !== 'undefined' && window.document)) {
  //     return null;
  //   }

  return (
    <div data-cy-isready={token ? 'true' : 'false'} {...register('captchaToken')}>
      <HCaptcha
        size={size}
        sitekey={sitekey}
        onVerify={setToken}
        onLoad={size === 'invisible' ? onLoad : () => {}}
        ref={captchaRef}
        languageOverride={locale}
      />
      <FormHelperText error>{errors?.captchaToken?.message as any}</FormHelperText>
    </div>
  );
};
