import { useEffect, useRef, useState } from "react";

const getOffset = (el: HTMLElement): { top: number; left: number } => {
    let offsetTop = el.offsetTop + el.offsetHeight;
    let offsetLeft = el.offsetLeft + el.offsetWidth / 2;
    let counter = 0;
    while (el.offsetParent) {
      el = el.offsetParent as HTMLElement;
      counter++;
      offsetTop += el.offsetTop;
      offsetLeft += el.offsetLeft;
      if (counter > 1) {
        break;
      }
    }
  
    return { top: offsetTop, left: offsetLeft };
  };
  
  export const useCustomAnchorPosition = ({enabled = false}) => {
    const inputRef = useRef<HTMLDivElement>(null);
    const [_rerenderFix, setRerenderFix] = useState(false);
    const getAnchorPosition = () => {
        // Calculate the anchor position 
      if (inputRef.current) {
        const offset = getOffset(inputRef.current);
        return { top: offset.top, left: offset.left };
      }
      return { top: 0, left: 0 }; // Fallback position
    };
    const getSelectProps = () => {
        if(!enabled) return {};
        return {
      ref: inputRef,
      MenuProps: {
        disablePortal: true,
        anchorReference: 'anchorPosition' as 'anchorPosition',
        anchorPosition: getAnchorPosition(),
      },
    }}
    useEffect(() => {
      if(enabled) {
        setRerenderFix(true);
      }
    },[enabled])
    return { inputRef, getSelectProps };
  }