import {useTranslation} from 'hooks/translations/useCustomTranslation';

import {MenuItem} from '@mui/material';

import {FormInputNumber} from 'components/basic-components/Form/FormInputNumber';
import {FormInputText} from 'components/basic-components/Form/FormInputText';
import { useCustomAnchorPosition } from 'utils/forms/useCustomAnchorPosition';

export const FormInputCompany = () => {
  const {t} = useTranslation('forms');
  return (
    <FormInputText
      sx={{width: '100%', height: '100%'}}
      label={t('company')}
      name="company"
      key="company"
      autoComplete="organization"
    />
  );
};

export const FormInputLastname = () => {
  const {t} = useTranslation('forms');
  return (
    <FormInputText
      name="lastName"
      label={t('lastname')}
      sx={{width: '100%', height: '100%'}}
      autoComplete={'family-name'}
    />
  );
};

export const FormInputFirstname = () => {
  const {t} = useTranslation('forms');
  return (
    <FormInputText
      label={t('firstname')}
      sx={{width: '100%', height: '100%'}}
      name="firstName"
      key="firstName"
      autoComplete="given-name"
    />
  );
};

export const FormInputPhone = () => {
  const {t} = useTranslation('forms');
  return (
    <FormInputNumber
      label={t('phoneNumber')}
      placeholder={t('placePhoneNumber')}
      sx={{width: '100%', height: '100%'}}
      name="phoneNumber"
      key="phoneNumber"
      autoComplete="tel-national"
      regex={/^[0-9]+$/}
      validateOnBlur={true}
      inputProps={{inputMode: 'numeric'}}
    />
  );
};

export const FormInputEmail = props => {
  const {t} = useTranslation(['forms', 'common']);
  return (
    <FormInputText
      label={t('email', {ns: 'common'})}
      sx={{width: '100%', height: '100%'}}
      name="email"
      key="email"
      autoComplete="email"
      validateOnBlur={true}
      {...props}
    />
  );
};


export const FormInputSalutation = ({customAnchor}: {customAnchor?: boolean}) => {
  const { t } = useTranslation('forms');
  const {getSelectProps } = useCustomAnchorPosition({enabled: customAnchor});
  const selectProps = getSelectProps();
  return (
    <FormInputText
      sx={{ width: '100%', height: '100%' }}
      select
      SelectProps={selectProps}
      variant="standard"
      label={t('salutation')}
      placeholder={t('salutation')}
      name="salutation"
      key="salutation"
    >
      <MenuItem value={'contact.salutation.mr'}>{t('contact.salutation.mr')}</MenuItem>
      <MenuItem value={'contact.salutation.mrs'}>{t('contact.salutation.mrs')}</MenuItem>
      <MenuItem value={'contact.salutation.divers'}>{t('contact.salutation.divers')}</MenuItem>
      <MenuItem value={'contact.salutation.mr_dr'}>{t('contact.salutation.mr_dr')}</MenuItem>
      <MenuItem value={'contact.salutation.mrs_dr'}>{t('contact.salutation.mrs_dr')}</MenuItem>
    </FormInputText>
  );
};

export const FormInputStreet = () => {
  const {t} = useTranslation('forms');
  return (
    <FormInputText
      label={t('streetHouseNumber')}
      placeholder={t('placeStreetHouseNumber')}
      sx={{width: '100%', height: '100%'}}
      name="street"
      key="street"
      autoComplete="address-line1"
    />
  );
};

export const FormInputZip = () => {
  const {t} = useTranslation('forms');
  return (
    <FormInputNumber
      label={t('zip')}
      placeholder={t('placeZip')}
      sx={{width: '100%', height: '100%'}}
      name="zip"
      key="zip"
      maxLength={5}
      autoComplete="postal-code"
      inputProps={{inputMode: 'numeric'}}
    />
  );
};

export const FormInputCity = () => {
  const {t} = useTranslation('forms');
  return (
    <FormInputText
      label={t('city')}
      sx={{width: '100%', height: '100%'}}
      name="city"
      key="city"
      autoComplete="locality"
    />
  );
};
